import React, {useEffect, useState, useCallback, Suspense, lazy} from 'react';
import {BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { ThemeProvider, createTheme, responsiveFontSizes } from '@mui/material/styles';
import { recentLogin } from './services/guiService';
import { HelmetProvider } from "react-helmet-async";
import axios from 'axios';
// page elements
import LoadingIcon from './components/library/loadingIcon';
import ErrorBoundary from './components/library/errorBoundary';
import PublicHeader from './components/nav/headerPublic';
import AppHeader from './components/nav/headerApp';
// main pages to preload
import Home from './components/main/home';
import Features from './components/main/features';
import Pricing from './components/main/pricing';
import Resources from './components/main/resources';

// less important pages to lazy load
const About = lazy(() => import('./components/main/about'));
const Article = lazy(() => import('./components/main/article'));
const Support = lazy(() => import('./components/main/support'));
const TOS = lazy(() => import('./components/main/tos'));
const Privacy = lazy(() => import('./components/main/privacy'));
const Cookie = lazy(() => import('./components/main/cookie-policy'));
const Unsubscribe = lazy(() => import('./components/main/unsubscribe'));
const StyleGuide = lazy(() => import('./components/main/style-guide'));
const NotFound = lazy(() => import('./components/main/404'));
const Checkout = lazy(() => import('./components/main/checkout'));
// site sections to lazy load
const Admin = lazy(() => import('./components/admin/app'));
const MainApp = lazy(() => import('./components/app/app')); 

let theme = createTheme({
  typography: {
    body1: {
      color: '#5B5B5B',
    },
    body2: {
      color: '#5B5B5B',
    },
    h1: {
      color: '#132e53',
    },
    h2: {
      color: '#132e53',
    },
    h3: {
      color: '#132e53',
    },
    h4: {
      color: '#132e53',
    },
    h5: {
      color: '#007377',
    },
    h6: {
      color: '#007377',
    },
  },
  palette: {
    type: 'light',
    primary: {
      main: '#007377'
    },
    warning: {
      main: '#B86C0F'
    },
    error: {
      main: '#E00444'
    },
    success: {
      main: '#5DC98B',
    },
    secondary: {
      main: '#a8d5ba',
    },
    headers: {
      main: '#132e53',
    },
    text: {
      primary: '#5B5B5B',
      secondary: 'rgba(19,46,83,0.54)',
    },
    divider: '#c6e6d3',
    oldDiverColor: '#a8d5ba'
  },
  components: {
    // Name of the component
    MuiToolbar: {
      styleOverrides: {
          dense: {
              height: 64,
              minHeight: 64
          }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(168, 212, 185, 0.8)',
        },
        invisible: {
          backgroundColor: 'rgb(0, 0, 0, 0)',
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          boxShadow: '0px 11px 15px -7px rgb(20 46 83 / 30%), 0px 24px 38px 3px rgb(20 46 83 / 24%), 0px 9px 46px 8px rgb(20 46 83 / 22%)',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          paddingLeft: '12px',
          paddingRight: '12px',
        },
      },
    },
  },
});
theme = responsiveFontSizes(theme);

// Show the active Header based on the location and login status
function ActiveNav(props) {
  const location = useLocation();
  const appPaths = ['/app', '/admin', '/invite', '/login', '/a/'];
  let iAmInApp = false;
  appPaths.forEach( (path) => {
    if ( String(location.pathname).slice(0, path.length) === path ) iAmInApp = true;
  });
  if (props.appRoot.user && iAmInApp) {
    return <AppHeader appRoot={props.appRoot} />
  } else {
    return <PublicHeader appRoot={props.appRoot} />
  }
}

// The app router, navigation, and user state
function App() {

  const [user, setUser] = useState('');
  const [loadingState, setLoadingState] = useState('loading');
  const [blockedUser, setBlockedUser] = useState(false);
  const [fadeFooter, setFadeFooter] = useState(false);

  // Get a specific cookie
  const getCookie = (name) => {
    return document.cookie.split('; ').filter(row => row.startsWith(name + '=')).map(c=>c.split('=')[1])[0];
  }

  // For the test server
  const testServerCheck = useCallback( () => {
    let urlVar;
    const urlParams = window.location.search.substring(1);
    if (urlParams && urlParams[0] === 't') urlVar = urlParams.replace('t=','');
    const testCookie = getCookie('test');
    if (urlVar && urlVar === process.env.REACT_APP_TEST_COOKIE) {
      window.history.pushState({}, document.title, "/" );
      document.cookie = `test=${process.env.REACT_APP_TEST_COOKIE};max-age=2592000`;
    } else if (!testCookie || (testCookie !== process.env.REACT_APP_TEST_COOKIE)) {
      setBlockedUser(true);
    }
  }, []);

  // Update the local user and store the fact that you are logged in in the session
  const updateUser = useCallback( (obj) => {
    const newUser = obj && user ? {...user, ...obj} : obj;
    setUser(newUser);
    if (newUser) {
      sessionStorage.setItem('user', JSON.stringify(newUser));
      const nowTime = newUser.exp ? newUser.exp : new Date(Date.now() + (12*60*60*1000));
      localStorage.setItem('lastLogin', JSON.stringify({login: new Date(), exp: nowTime}));
    } else {
      sessionStorage.removeItem('user');
      localStorage.removeItem('lastLogin');
    }
  }, [user]);

  // Get the user from the server
  const getUser = useCallback( async (options) => {
    const urlOptions = options ? options : '?includeFeatures=1&includeUsage=1';
    const getUrl = `${process.env.REACT_APP_API_URL}/users/s/${urlOptions}`;
    try {
      const response = await axios.get(getUrl, {withCredentials: true, headers: {'Cache-Control': 'no-cache','Pragma': 'no-cache','Expires': '0'}});
      const newUser = user ? {...user, ...response.data} : response.data;
      updateUser(newUser);
      return newUser;
    } catch (error) {
      updateUser('');
      return null;
    }
  }, [user, updateUser]);

  // Global logout function
  const logout = async (token) => {
    let csrfToken = token;
    updateUser('');
    try { 
      if (!csrfToken) {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/app`, {withCredentials: true});
        csrfToken = response.data.csrfToken;
      }
      axios.delete(`${process.env.REACT_APP_API_URL}/login`, {withCredentials: true, data: {}, headers: {'CSRF-Token': csrfToken} })
      .then( (response) => { return response.data } )
      .catch( () => { return {} } );
    } catch (error) {
      if (process.env.REACT_APP_REACT_ENV !== 'production') console.log(error);
      return {};
    }
  }

  // Global functions used to control login and layout
  const appRoot = {
    user: user,
    setUser: updateUser,
    refreshUser: getUser,
    logout: logout,
    fadeFooter: fadeFooter,
    setFadeFooter: setFadeFooter
  }

  useEffect( () => {
    // On first load
    if(loadingState === 'loading') {
      // Test environment requires the URL variable or the cookie
      if (process.env.REACT_APP_ENV === 'test') testServerCheck();
      setLoadingState('complete');
      // Check the user if you recently logged in
      const localUser = recentLogin() ? sessionStorage.getItem('user') : null;
      // If user stored in session (same tab)
      if (localUser) {
        const userObj = JSON.parse(localUser);
        setUser(userObj);
      // If they logged in recently, but session is empty (new tab or remember me), we'll go check if they are logged in
      } else if (recentLogin()) {
        getUser();
      }
      setLoadingState('complete');
    }
  }, [loadingState, user, testServerCheck, getUser]);


  // allow state to evaluate the cookie before deciding status
  if (loadingState === 'loading') {
    return <Box></Box>
  // if on test server
  } else if (blockedUser) {
    return (
      <Box>Access denied.</Box>
    )
  // full site 
  } else {
    return (
        <ErrorBoundary>
        <HelmetProvider>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <ActiveNav appRoot={appRoot} />
              <Suspense fallback={<LoadingIcon />}>
                <Routes>
                  {/* Main site */}
                  <Route path="/" element={<Home appRoot={appRoot} />} />
                  <Route path="/about" element={<About appRoot={appRoot} />} />
                  <Route path="/features" element={<Features appRoot={appRoot} />} />
                  <Route path="/pricing" element={<Pricing appRoot={appRoot} />} />
                  <Route path="/support" element={<Support appRoot={appRoot} />} />
                  <Route path="/resources/:category/:uid" element={<Article appRoot={appRoot} />} />
                  <Route path="/resources/:category" element={<Resources appRoot={appRoot} />} />
                  <Route path="/resources" element={<Resources appRoot={appRoot} />} />
                  <Route path="/privacy-policy" element={<Privacy appRoot={appRoot} />} />
                  <Route path="/terms-of-service" element={<TOS appRoot={appRoot} />} />
                  <Route path="/cookie-policy" element={<Cookie appRoot={appRoot} />} />
                  <Route path="/mail-preferences" element={<Unsubscribe appRoot={appRoot} />} />
                  <Route path="/checkout" element={<Checkout appRoot={appRoot} />} />
                  <Route path="/404" element={<NotFound appRoot={appRoot} />} />
                  {process.env.REACT_APP_ENV !== 'production' ? <Route path="/style-guide" element={<StyleGuide appRoot={appRoot} />} /> : null}
                  {/* Application */}
                  <Route path="/admin/*" element={<Admin appRoot={appRoot} />} />
                  <Route path="/*" element={<MainApp appRoot={appRoot} />} />
                </Routes>
              </Suspense>
            </BrowserRouter>
          </ThemeProvider>
        </HelmetProvider>
        </ErrorBoundary>
    );
  }
}

export default App;
