import { useState, useEffect } from 'react';
import { Container, Box, CircularProgress } from '@mui/material';

const LoadingIcon = () => {

  const [firstLoad, setFirstLoad] = useState(true);
  const [displayNow, setDisplayNow] = useState(false);

  useEffect( () => {
    if (!displayNow && firstLoad) {
      setFirstLoad(false);
      const pause = async () => {
        await new Promise(resolve => setTimeout(resolve, 200));
        setDisplayNow(true);
      }
      pause();
    }
  }, [displayNow, firstLoad]);

  return  (
    <Container align="center" maxWidth="sm">
      <Box justifyContent="center" mt={10}>
        <CircularProgress sx={{display: displayNow ? 'block' : 'none'}} />
      </Box>
    </Container>
  )
}

export default LoadingIcon;