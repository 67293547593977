import React from 'react';
import { styled } from '@mui/material/styles';
import { Container, Link, Toolbar, AppBar, Box, IconButton, Menu, MenuItem, Button, Stack, Tooltip } from '@mui/material';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import EventIcon from '@mui/icons-material/Event';
import logo from '../../images/nav/Meetify-nav.png';

const PREFIX = 'Header';

const classes = {
    label: `${PREFIX}-label`,
};

const StyledAppBar = styled(AppBar)({
    [`& .${classes.label}`]: {
        marginLeft: '0px'
    },
});


const Header = (props) => {

    const appRoot = props.appRoot ? props.appRoot : {};
    const [accountEl, setAccountEl] = React.useState(null);
    const [mobileEl, setMobileEl] = React.useState(null);
    const location = useLocation();

    const handleClick = (event) => {
      setMobileEl(event.currentTarget);
    };

    const handleClick2 = (event) => {
        setAccountEl(event.currentTarget);
      };
  
    const handleClose = () => {
      setAccountEl(null);
      setMobileEl(null);
    };

        
    return (
        <StyledAppBar position="static">
          <Container ml="0" mr="0">
            <Toolbar disableGutters={true} variant="dense">
              <Box sx={{paddingTop: '13px'}}><Link component={ReactRouterLink} to="/" color="primary.contrastText"><img src={logo} border="0" height="40" alt="Meetify" /></Link></Box>
                <Box sx={{display:{ xs: 'none', sm: 'block'}, flexGrow: 1}} ml={2} >
                </Box>
                <Box display={{ xs: 'none', sm: 'block' }}>
                <Stack direction="row" spacing={3} alignItems="center" justifyContent="flex-end">
                    <Button 
                        aria-controls="simple-menu" 
                        variant="contained" 
                        color="primary"
                        disableElevation
                        component={ReactRouterLink} to="/app"
                    >
                        Meetups
                    </Button>  
                    <Button 
                        aria-controls="simple-menu" 
                        aria-haspopup="true" 
                        variant="contained" 
                        color="primary"
                        classes={{endIcon: classes.label}}
                        disableElevation
                        endIcon={<ArrowDropDownIcon m={0} />} 
                        onClick={handleClick2}
                    id="navAccount">
                        {appRoot.user.firstName}
                    </Button>
                        <Button variant="contained" component={ReactRouterLink} to="/invite" color="secondary"><strong>Invite</strong></Button>
                      </Stack>
                      <Menu
                            id="account-menu"
                            disableScrollLock={true}
                            anchorEl={accountEl}
                            keepMounted
                            open={Boolean(accountEl)}
                            onClose={handleClose}
                            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                            transformOrigin={{ vertical: "top", horizontal: "center" }}
                        >
                          {location.pathname.includes('/settings') ? 
                            <MenuItem><Link id="accountLink" href="/app/settings/profile" onClick={()=>setAccountEl(false)}>Account</Link></MenuItem>
                             : 
                            <MenuItem><Link id="accountLink" component={ReactRouterLink} to="/app/settings/profile" onClick={()=>setAccountEl(false)}>Account</Link></MenuItem>
                            }       
                            {appRoot.user.settings && appRoot.user.settings.admin && appRoot.user.settings.admin.isAdmin ?
                            <MenuItem><Link id="adminLink" component={ReactRouterLink} to={`/admin${appRoot.user.settings && appRoot.user.settings.admin && appRoot.user.settings.admin.support ? '/support/search' : '/stats/planners'}`} onClick={()=>setAccountEl(false)}><strong>ADMIN</strong></Link></MenuItem>
                            : null}
                            <MenuItem><Link id="logoutLink" component={ReactRouterLink} to="/login/goodbye" onClick={()=>setAccountEl(false)}>Logout</Link></MenuItem>
                        </Menu>
                    </Box>
                <Box display={{ xs: 'block', sm: 'none', flexGrow: 1 }}>
                  <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">

                    <Tooltip title="Meetups" arrow>
                      <IconButton color="inherit" aria-label="invite" size="large" component={ReactRouterLink} to="/app">
                        <EventIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Account" arrow>
                    <IconButton
  
                        color="inherit"
                        aria-label="menu"
                        onClick={handleClick}
                        size="large">
                        <AccountCircleIcon />
                    </IconButton>
                    </Tooltip>
                    <Menu
                        id="simple-menu"
                        anchorEl={mobileEl}
                        keepMounted
                        disableScrollLock={true}
                        open={Boolean(mobileEl)}
                        onClose={handleClose}
                    >
                          {location.pathname.includes('/settings') ? 
                            <MenuItem><Link id="accountLink" href="/app/settings/profile" onClick={()=>setMobileEl(false)}>Account</Link></MenuItem>
                             : 
                             <MenuItem><Link id="accountLink" component={ReactRouterLink} to="/app/settings/profile" onClick={()=>setMobileEl(false)}>Account</Link></MenuItem>
                           }    
                        
                        {appRoot.user.settings && appRoot.user.settings.admin && appRoot.user.settings.admin.isAdmin ? <MenuItem><Link id="adminLink" component={ReactRouterLink} to={`/admin${appRoot.user.settings && appRoot.user.settings.admin && appRoot.user.settings.admin.support ? '/support/search' : '/stats/planners'}`} onClick={()=>setMobileEl(false)}><strong>ADMIN</strong></Link></MenuItem> : null}
                        <MenuItem><Link id="logoutLink" component={ReactRouterLink} to="/login/goodbye" onClick={()=>setMobileEl(false)}>Logout</Link></MenuItem>
                    </Menu>
                   
                    <Tooltip title="Invite" arrow>
                      <IconButton color="inherit" aria-label="invite" size="large" component={ReactRouterLink} to="/invite">
                        <AddCircleOutline />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                </Box>
            </Toolbar>
            </Container>
            {appRoot.user && appRoot.user.isAdmin ? <Box sx={{height: '30px', backgroundColor: 'secondary.main', fontWeight: 'bold', display:'flex', alignItems: 'center', justifyContent: 'center', color: 'headers.main'}} typography="body1">IMPORTANT: You are logged in as {appRoot.user.firstName} {appRoot.user.lastName}</Box>: null}

            </StyledAppBar>
    );

}

export default Header;

